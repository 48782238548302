import React from "react"

export default function FileInputHeroComponent() {
  return (
    <>
      {/*<!-- Component: Dropzone file input that accepts specific types --> */}
      <div className="relative my-6">
        <input
          id="id-dropzone02"
          name="file-upload"
          type="file"
          className="peer hidden"
          accept=".gif,.jpg,.png,.jpeg"
          multiple
        />
        <label
          for="id-dropzone02"
          className="flex cursor-pointer flex-col items-center gap-6 rounded border border-dashed border-slate-300 px-6 py-10 text-center"
        >
          <span className="inline-flex h-12 items-center justify-center self-center rounded bg-slate-100/70 px-3 text-slate-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-label="File input icon"
              role="graphics-symbol"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </span>
          <p className="flex flex-col items-center justify-center gap-1 text-sm">
            <span className="text-emerald-500 hover:text-emerald-500">
              Upload media
              <span className="text-slate-500"> or drag and drop </span>
            </span>
            <span className="text-slate-600"> PNG, JPG or GIF up to 10MB </span>
          </p>
        </label>
      </div>
      {/*<!-- End Dropzone file input that accepts specific types --> */}
    </>
  )
}
