import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsFileInputAllButtonPreview(props) {
  //Base
  const {
    FormElementsFileRoundedLgButton,
    FormElementsFileRoundedBaseButton,
    FormElementsFileRoundedSmButton,
    FormElementsFileRoundedLgButtonLeadIcon,
    FormElementsFileRoundedBaseButtonLeadIcon,
    FormElementsFileRoundedSmButtonLeadIcon,
  } = props
  //Large
  const {
    FormElementsFileRoundedFullLgButton,
    FormElementsFileRoundedFullBaseButton,
    FormElementsFileRoundedFullSmButton,
    FormElementsFileRoundedFullLgButtonLeadIcon,
    FormElementsFileRoundedFullBaseButtonLeadIcon,
    FormElementsFileRoundedFullSmButtonLeadIcon,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-center justify-center gap-24 xl:flex-row ">
        <div className="w-fit">
          <h3 className="font-medium text-slate-500">Rounded:</h3>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedLgButton}
              componentName="FormElementsFileRoundedLgButton"
            >
              {/*<!-- Component: Button large file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b01"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b01"
                  className="inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none peer-disabled:cursor-not-allowed peer-disabled:border-emerald-300 peer-disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Button large file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedBaseButton}
              componentName="FormElementsFileRoundedBaseButton"
            >
              {/*<!-- Component: Button base sized file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b02"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b02"
                  className="inline-flex h-10 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Button base sized file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 mb-16 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedSmButton}
              componentName="FormElementsFileRoundedSmButton"
            >
              {/*<!-- Component: Button sm sized file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b03"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b03"
                  className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Button sm sized file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedLgButtonLeadIcon}
              componentName="FormElementsFileRoundedLgButtonLeadIcon"
            >
              {/*      <!-- Component: Button large file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b04"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b04"
                  className="inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none peer-disabled:cursor-not-allowed peer-disabled:border-emerald-300 peer-disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*      <!-- End Button large file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedBaseButtonLeadIcon}
              componentName="FormElementsFileRoundedBaseButtonLeadIcon"
            >
              {/*<!-- Component: Button base sized file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b05"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b05"
                  className="inline-flex h-10 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*<!-- End Button base sized file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedSmButtonLeadIcon}
              componentName="FormElementsFileRoundedSmButtonLeadIcon"
            >
              {/*<!-- Component: Button sm sized file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b06"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b06"
                  className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*<!-- End Button sm sized file input with leading icon --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="w-fit">
          <h3 className="font-medium text-slate-500">Rounded Full:</h3>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedFullLgButton}
              componentName="FormElementsFileRoundedFullLgButton"
            >
              {/*<!-- Component: Fully rounded button large file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b07"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b07"
                  className="inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none peer-disabled:cursor-not-allowed peer-disabled:border-emerald-300 peer-disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Fully rounded button large file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedFullBaseButton}
              componentName="FormElementsFileRoundedFullBaseButton"
            >
              {/*<!-- Component: Fully rounded button base sized file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b08"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b08"
                  className="inline-flex h-10 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Fully rounded button base sized file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 mb-16 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedFullSmButton}
              componentName="FormElementsFileRoundedFullSmButton"
            >
              {/*<!-- Component: Fully rounded button sm sized file input --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b09"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b09"
                  className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Fully rounded button sm sized file input --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedFullLgButtonLeadIcon}
              componentName="FormElementsFileRoundedFullLgButtonLeadIcon"
            >
              {/*<!-- Component: Fully rounded button large file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b10"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b10"
                  className="inline-flex h-12 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none peer-disabled:cursor-not-allowed peer-disabled:border-emerald-300 peer-disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*<!-- End Fully rounded button large file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={
                FormElementsFileRoundedFullBaseButtonLeadIcon
              }
              componentName="FormElementsFileRoundedFullBaseButtonLeadIcon"
            >
              {/*<!-- Component: Fully rounded button base sized file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b11"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b11"
                  className="inline-flex h-10 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*<!-- End Fully rounded button base sized file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="my-8 w-fit">
            <CopyComponent
              copyToClipboardCode={FormElementsFileRoundedFullSmButtonLeadIcon}
              componentName="FormElementsFileRoundedFullSmButtonLeadIcon"
            >
              {/*<!-- Component: Fully rounded button sm sized file input with leading icon --> */}
              <div className="relative inline-flex w-full items-center gap-2 rounded-full border border-slate-200 text-sm text-slate-500">
                <input
                  id="file-upload-b12"
                  name="file-upload"
                  type="file"
                  className="peer order-2 [&::file-selector-button]:hidden"
                />
                <label
                  for="file-upload-b12"
                  className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300"
                >
                  <span className="order-2">Upload a file</span>
                  <span className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </span>
                </label>
              </div>
              {/*<!-- End Fully rounded button sm sized file input with leading icon --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
