import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsFileInputAllPlainPreview from "../../../../library/components/form-elements/file/react/_preview/plain"
import FormElementsFileInputAllRoundedPreview from "../../../../library/components/form-elements/file/react/_preview/rounded"
import FormElementsFileInputAllButtonPreview from "../../../../library/components/form-elements/file/react/_preview/button"
import FormElementsFileInputAllDropzonePreview from "../../../../library/components/form-elements/file/react/_preview/dropzone"

import FileInputHeroComponent from "../../../../library/components/form-elements/file/react/_preview/FileInputHeroComponent"

// Plain Input Fields
// Basic base
const FormElementsFilePlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/base/basic.jsx")
const FormElementsFilePlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/base/basic.html")

// Basic lg
const FormElementsFilePlainLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/lg/basic.jsx")
const FormElementsFilePlainLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/lg/basic.html")

// Helper text base
const FormElementsFilePlainBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/base/helper_text.jsx")
const FormElementsFilePlainBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/base/helper_text.html")

// Helper text lg
const FormElementsFilePlainLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/lg/helper_text.jsx")
const FormElementsFilePlainLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/lg/helper_text.html")

// Lead icon base
const FormElementsFilePlainBaseLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/base/lead_icon.jsx")
const FormElementsFilePlainBaseLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/base/lead_icon.html")

// Lead icon lg
const FormElementsFilePlainLgLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/lg/lead_icon.jsx")
const FormElementsFilePlainLgLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/lg/lead_icon.html")

// Trail icon base
const FormElementsFilePlainBaseTrailIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/base/trail_icon.jsx")
const FormElementsFilePlainBaseTrailIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/base/trail_icon.html")

// Trail icon lg
const FormElementsFilePlainLgTrailIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/plain/lg/trail_icon.jsx")
const FormElementsFilePlainLgTrailIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/plain/lg/trail_icon.html")

// Rounded Input Fields
// Basic base
const FormElementsFileRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/basic.jsx")
const FormElementsFileRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/basic.html")

// Basic lg
const FormElementsFileRoundedLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/basic.jsx")
const FormElementsFileRoundedLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/basic.html")

// Helper text base
const FormElementsFileRoundedBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/helper_text.jsx")
const FormElementsFileRoundedBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/helper_text.html")

// Helper text lg
const FormElementsFileRoundedLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/helper_text.jsx")
const FormElementsFileRoundedLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/helper_text.html")

// Lead icon base
const FormElementsFileRoundedBaseLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/lead_icon.jsx")
const FormElementsFileRoundedBaseLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/lead_icon.html")

// Lead icon lg
const FormElementsFileRoundedLgLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/lead_icon.jsx")
const FormElementsFileRoundedLgLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/lead_icon.html")

// Trail icon base
const FormElementsFileRoundedBaseTrailIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/trail_icon.jsx")
const FormElementsFileRoundedBaseTrailIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/trail_icon.html")

// Trail icon lg
const FormElementsFileRoundedLgTrailIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/trail_icon.jsx")
const FormElementsFileRoundedLgTrailIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/trail_icon.html")

// Button Input Fields
//Rounded
// Basic lg
const FormElementsFileRoundedLgButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/button.jsx")
const FormElementsFileRoundedLgButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/button.html")

// Basic base
const FormElementsFileRoundedBaseButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/button.jsx")
const FormElementsFileRoundedBaseButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/button.html")

// Basic sm
const FormElementsFileRoundedSmButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/sm/button.jsx")
const FormElementsFileRoundedSmButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/sm/button.html")

// Leading icon lg
const FormElementsFileRoundedLgButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/lg/button-lead_icon.jsx")
const FormElementsFileRoundedLgButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/lg/button-lead_icon.html")

// Leading icon base
const FormElementsFileRoundedBaseButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/button-lead_icon.jsx")
const FormElementsFileRoundedBaseButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/button-lead_icon.html")

// Leading icon sm
const FormElementsFileRoundedSmButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/sm/button-lead_icon.jsx")
const FormElementsFileRoundedSmButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/sm/button-lead_icon.html")

//Fully rounded
// Basic lg
const FormElementsFileRoundedFullLgButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/lg/button.jsx")
const FormElementsFileRoundedFullLgButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/lg/button.html")

// Basic base
const FormElementsFileRoundedFullBaseButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/base/button.jsx")
const FormElementsFileRoundedFullBaseButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/base/button.html")

// Basic sm
const FormElementsFileRoundedFullSmButtonJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/sm/button.jsx")
const FormElementsFileRoundedFullSmButtonHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/sm/button.html")

// Leading icon lg
const FormElementsFileRoundedFullLgButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/lg/button-lead_icon.jsx")
const FormElementsFileRoundedFullLgButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/lg/button-lead_icon.html")

// Leading icon base
const FormElementsFileRoundedFullBaseButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/base/button-lead_icon.jsx")
const FormElementsFileRoundedFullBaseButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/base/button-lead_icon.html")

// Leading icon sm
const FormElementsFileRoundedFullSmButtonLeadIconJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded-full/sm/button-lead_icon.jsx")
const FormElementsFileRoundedFullSmButtonLeadIconHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded-full/sm/button-lead_icon.html")

// Dropzone Input Fields
// Basic
const FormElementsFileRoundedBaseDropzoneJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/dropzone.jsx")
const FormElementsFileRoundedBaseDropzoneHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/dropzone.html")

// Specified
const FormElementsFileRoundedBaseDropzoneSpecifiedJsx = require("!!raw-loader!../../../../library/components/form-elements/file/react/rounded/base/dropzone-specified.jsx")
const FormElementsFileRoundedBaseDropzoneSpecifiedHTML = require("!!raw-loader!../../../../library/components/form-elements/file/html/rounded/base/dropzone-specified.html")

export default function FormElementFileInputPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsButtonFileInput",
      title: "Button file inputs",
      active_tab: 1,
    },
    {
      component_name: "FormElementsBasicFileInput",
      title: "Basic",
      active_tab: 1,
    },
    {
      component_name: "FormElementsFileInputRounded",
      title: "Outline",
      active_tab: 1,
    },
    {
      component_name: "FormElementsDropzoneFileInput",
      title: "Dropzone",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS File Inputs - WindUI Component Library"
        ogtitle="Tailwind CSS File Inputs - WindUI Component Library"
        description="The File Input component lets the user choose one or more files from their device. Built with Tailwind CSS by WindUI."
        ogdescription="The File Input component lets the user choose one or more files from their device. Built with Tailwind CSS by WindUI."
        url="components/form-elements/file-inputs/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS File Inputs"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">File Inputs</h1>
          <p>
            The File Input component lets the user choose one or more files from
            their device.
          </p>

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose w-full max-w-full"
              aria-multiselectable="false"
            >
              <FileInputHeroComponent />
            </section>
          </div>
        </Section>
        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="FormElementsButtonFileInput">Button File Input</h3>

          <PreviewBlock
            id="FormElementsButtonFileInput"
            HtmlComponent={FormElementsFileRoundedLgButtonHTML.default}
            JsxComponent={FormElementsFileRoundedLgButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsFileInputAllButtonPreview
                FormElementsFileRoundedLgButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedLgButtonHTML.default
                    : FormElementsFileRoundedLgButtonJsx.default
                }
                FormElementsFileRoundedBaseButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedBaseButtonHTML.default
                    : FormElementsFileRoundedBaseButtonJsx.default
                }
                FormElementsFileRoundedSmButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedSmButtonHTML.default
                    : FormElementsFileRoundedSmButtonJsx.default
                }
                FormElementsFileRoundedLgButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedLgButtonLeadIconHTML.default
                    : FormElementsFileRoundedLgButtonLeadIconJsx.default
                }
                FormElementsFileRoundedBaseButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedBaseButtonLeadIconHTML.default
                    : FormElementsFileRoundedBaseButtonLeadIconJsx.default
                }
                FormElementsFileRoundedSmButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedSmButtonLeadIconHTML.default
                    : FormElementsFileRoundedSmButtonLeadIconJsx.default
                }
                FormElementsFileRoundedFullLgButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullLgButtonHTML.default
                    : FormElementsFileRoundedFullLgButtonJsx.default
                }
                FormElementsFileRoundedFullBaseButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullBaseButtonHTML.default
                    : FormElementsFileRoundedFullBaseButtonJsx.default
                }
                FormElementsFileRoundedFullSmButton={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullSmButtonHTML.default
                    : FormElementsFileRoundedFullSmButtonJsx.default
                }
                FormElementsFileRoundedFullLgButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullLgButtonLeadIconHTML.default
                    : FormElementsFileRoundedFullLgButtonLeadIconJsx.default
                }
                FormElementsFileRoundedFullBaseButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullBaseButtonLeadIconHTML.default
                    : FormElementsFileRoundedFullBaseButtonLeadIconJsx.default
                }
                FormElementsFileRoundedFullSmButtonLeadIcon={
                  activeTabs[0].active_tab === 1
                    ? FormElementsFileRoundedFullSmButtonLeadIconHTML.default
                    : FormElementsFileRoundedFullSmButtonLeadIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="FormElementsBasicFileInput">Basic File Input</h3>

          <PreviewBlock
            id="FormElementsBasicFileInput"
            HtmlComponent={FormElementsFilePlainBaseBasicHTML.default}
            JsxComponent={FormElementsFilePlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsFileInputAllPlainPreview
                FormElementsFilePlainBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainBaseBasicHTML.default
                    : FormElementsFilePlainBaseBasicJsx.default
                }
                FormElementsFilePlainLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainLgBasicHTML.default
                    : FormElementsFilePlainLgBasicJsx.default
                }
                FormElementsFilePlainBaseHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainBaseHelperTextHTML.default
                    : FormElementsFilePlainBaseHelperTextJsx.default
                }
                FormElementsFilePlainLgHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainLgHelperTextHTML.default
                    : FormElementsFilePlainLgHelperTextJsx.default
                }
                FormElementsFilePlainBaseLeadIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainBaseLeadIconHTML.default
                    : FormElementsFilePlainBaseLeadIconJsx.default
                }
                FormElementsFilePlainLgLeadIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainLgLeadIconHTML.default
                    : FormElementsFilePlainLgLeadIconJsx.default
                }
                FormElementsFilePlainBaseTrailIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainBaseTrailIconHTML.default
                    : FormElementsFilePlainBaseTrailIconJsx.default
                }
                FormElementsFilePlainLgTrailIcon={
                  activeTabs[1].active_tab === 1
                    ? FormElementsFilePlainLgTrailIconHTML.default
                    : FormElementsFilePlainLgTrailIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="FormElementsFileInputRounded">Outline File Input</h3>

          <PreviewBlock
            id="FormElementsFileInputRounded"
            HtmlComponent={FormElementsFileRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsFileRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsFileInputAllRoundedPreview
                FormElementsFileRoundedBaseBasic={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedBaseBasicHTML.default
                    : FormElementsFileRoundedBaseBasicJsx.default
                }
                FormElementsFileRoundedLgBasic={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedLgBasicHTML.default
                    : FormElementsFileRoundedLgBasicJsx.default
                }
                FormElementsFileRoundedBaseHelperText={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedBaseHelperTextHTML.default
                    : FormElementsFileRoundedBaseHelperTextJsx.default
                }
                FormElementsFileRoundedLgHelperText={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedLgHelperTextHTML.default
                    : FormElementsFileRoundedLgHelperTextJsx.default
                }
                FormElementsFileRoundedBaseLeadIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedBaseLeadIconHTML.default
                    : FormElementsFileRoundedBaseLeadIconJsx.default
                }
                FormElementsFileRoundedLgLeadIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedLgLeadIconHTML.default
                    : FormElementsFileRoundedLgLeadIconJsx.default
                }
                FormElementsFileRoundedBaseTrailIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedBaseTrailIconHTML.default
                    : FormElementsFileRoundedBaseTrailIconJsx.default
                }
                FormElementsFileRoundedLgTrailIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsFileRoundedLgTrailIconHTML.default
                    : FormElementsFileRoundedLgTrailIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="FormElementsDropzoneFileInput">Dropzone</h3>

          <PreviewBlock
            id="FormElementsDropzoneFileInput"
            HtmlComponent={FormElementsFileRoundedBaseDropzoneHTML.default}
            JsxComponent={FormElementsFileRoundedBaseDropzoneJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsFileInputAllDropzonePreview
                FormElementsFileRoundedBaseDropzone={
                  activeTabs[3].active_tab === 1
                    ? FormElementsFileRoundedBaseDropzoneHTML.default
                    : FormElementsFileRoundedBaseDropzoneJsx.default
                }
                FormElementsFileRoundedBaseDropzoneSpecified={
                  activeTabs[3].active_tab === 1
                    ? FormElementsFileRoundedBaseDropzoneSpecifiedHTML.default
                    : FormElementsFileRoundedBaseDropzoneSpecifiedJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The file input provides a button that opens up a file picker dialog
            that allows the user to choose a file.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            File input fields come in four basic styles (<strong>basic</strong>,{" "}
            <strong>outlined</strong>, <strong>button</strong> and{" "}
            <strong>dropzone</strong>) and may include the following elements:
          </p>
          <ul>
            <li>
              <strong>Icon:</strong> A 1.25rem (20px) in width and height icon.
              Icons can be leading or trailing.
            </li>
            <li>
              <strong>Helper text:</strong> text font size of 0.75rem (12px) and
              a line height of 1rem (16px).
            </li>
            <li>
              <strong>Accept attribute:</strong> The accept specifier is a
              string that describes a type of file that may be selected by the
              user in an input element. For instance{" "}
              <code>&lt;accept=".gif,.jpg,.png,.jpeg"&gt;</code> accepts PNG,
              JPEG or GIF files.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            File inputs are built with the native{" "}
            <code>&lt;input type="file"&gt;</code> element and no additional
            accessibility roles are required. For better accessibility when
            using the element:
          </p>
          <ul>
            <li>
              An id attribute to allow the file <code>&lt;input&gt;</code> to be
              associated with a <code>&lt;label&gt;</code> element for
              accessibility purposes.
            </li>
            <li>
              A name attribute to set the name of the associated data point
              submitted to the server when the form is submitted.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
