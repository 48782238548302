import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsFileInputAllPlainPreview(props) {
  //Base
  const {
    FormElementsFilePlainBaseBasic,
    FormElementsFilePlainBaseHelperText,
    FormElementsFilePlainBaseLeadIcon,
    FormElementsFilePlainBaseTrailIcon,
  } = props
  //Large
  const {
    FormElementsFilePlainLgBasic,
    FormElementsFilePlainLgHelperText,
    FormElementsFilePlainLgLeadIcon,
    FormElementsFilePlainLgTrailIcon,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="font-medium text-slate-500">Base Size:</h3>

          <div className="mb-5 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainBaseBasic}
              componentName="FormElementsFilePlainBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic base sized file input --> */}
              <div className="relative my-6">
                <input
                  id="id-file01"
                  type="file"
                  name="id-file01"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2.5 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Basic base sized file input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainBaseHelperText}
              componentName="FormElementsFilePlainBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic base sized file input with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-file01"
                  type="file"
                  name="id-file01"
                  accept="image/*"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2.5 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select any image files</span>
                </small>
              </div>
              {/*<!-- End Basic base sized file input with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainBaseLeadIcon}
              componentName="FormElementsFilePlainBaseLeadIcon"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic base sized file input with leading icon --> */}
              <div className="relative my-6">
                <input
                  id="id-file01"
                  type="file"
                  name="id-file01"
                  multiple
                  className="peer relative w-full border-b border-slate-200 px-4 py-2.5 pl-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="absolute left-4 top-3 h-5 w-5 stroke-slate-400 text-slate-400 peer-disabled:cursor-not-allowed"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select multiple files</span>
                </small>
              </div>
              {/*<!-- End Basic base sized file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainBaseTrailIcon}
              componentName="FormElementsFilePlainBaseTrailIcon"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic base sized file input with trailing icon --> */}
              <div className="relative my-6">
                <input
                  id="id-file01"
                  type="file"
                  name="id-file01"
                  multiple
                  className="peer relative w-full border-b border-slate-200 px-4 py-2.5 pr-12 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="absolute right-4 top-3 h-5 w-5 stroke-slate-400 text-slate-400 peer-disabled:cursor-not-allowed"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select multiple files</span>
                </small>
              </div>
              {/*<!-- End Basic base sized file input with trailing icon --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="w-full">
          <h3 className="font-medium text-slate-500">Large Size:</h3>

          <div className="mb-5 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainLgBasic}
              componentName="FormElementsFilePlainLgBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic large file input --> */}
              <div className="relative my-6">
                <input
                  id="id-file02"
                  type="file"
                  name="id-file02"
                  className="peer relative w-full border-b border-slate-200 px-4 py-3 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
              </div>
              {/*<!-- End Basic large file input --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainLgHelperText}
              componentName="FormElementsFilePlainLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic large file input with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-file02"
                  type="file"
                  name="id-file02"
                  accept="image/*"
                  className="peer relative w-full border-b border-slate-200 px-4 py-3 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select any image files</span>
                </small>
              </div>
              {/*<!-- End Basic large file input with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainLgLeadIcon}
              componentName="FormElementsFilePlainLgLeadIcon"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic large file input with leading icon --> */}
              <div className="relative my-6">
                <input
                  id="id-file02"
                  type="file"
                  name="id-file02"
                  multiple
                  className="peer relative w-full border-b border-slate-200 px-4 py-3 pl-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="absolute left-4 top-4 h-5 w-5 stroke-slate-400 text-slate-400 peer-disabled:cursor-not-allowed"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select multiple files</span>
                </small>
              </div>
              {/*<!-- End Basic large file input with leading icon --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsFilePlainLgTrailIcon}
              componentName="FormElementsFilePlainLgTrailIcon"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic large file input with trailing icon --> */}
              <div className="relative my-6">
                <input
                  id="id-file02"
                  type="file"
                  name="id-file02"
                  multiple
                  className="peer relative w-full border-b border-slate-200 px-4 py-3 pr-12 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400 [&::file-selector-button]:hidden"
                />
                <label
                  for="id-file02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  {" "}
                  Upload a file{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="absolute right-4 top-4 h-5 w-5 stroke-slate-400 text-slate-400 peer-disabled:cursor-not-allowed"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                  />
                </svg>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>The user can select multiple files</span>
                </small>
              </div>
              {/*<!-- End Basic large file input with trailing icon --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
